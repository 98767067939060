.supportCenterWrapper a:hover {
  text-decoration: none;
  color: black;
}

.supportCenterWrapper a {
  color: black;
}

.supportCard {
  text-align: center;
  padding: 60px 15px;
  cursor: pointer;
  transition: transform 0.25s, background-color 0.25s;
}

.supportTitle {
  font-size: 25px;
  font-weight: bold;
}

.supportCard:hover {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #FAFAFA;
  transform: scale(1.1, 1.1);
}

.gettingStartedImage,
.emailImage,
.faqLogo,
.jiraServiceDeskLogo {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

.jiraServiceDeskLogo {
  height: 100px;
  background-image: url(../../common/images/servicedesk.png);
  background-size: auto 100%;
}

.faqLogo {
  height: 100px;
  background-image: url(../../common/images/faq.png);
  background-size: auto 100%;
}

.emailImage {
  height: 100px;
  background-image: url(../../common/images/email.png);
  background-size: auto 100%;
}

.gettingStartedImage {
  height: 100px;
  background-image: url(../../common/images/doc.png);
  background-size: auto 100%;
}
