.tooltip {
  position: relative;
  display: inline-block;
}

.tooltipTrigger {
  display: inline-block;
  text-decoration: underline;
}

.tooltipBubble.bigTooltip {
  width: 550px;
  pointer-events: all;
}

.tooltipBubble.extraBigTooltip {
  width: 650px;
  pointer-events: all;
}

.topTooltip.topTooltip:after {
  bottom: 0%;
}

.topTooltip .tooltipMessage {
  padding: 20px;
}

.tooltipBubble {
  position: absolute;
  width: 200px;
  z-index: 1000;
  pointer-events: none;
}

.tooltipBubble:after {
  content: '';
  position: absolute;
}

.topTooltip {
  bottom: 105%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.topTooltip:after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid rgba(0,0,0,.9);
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.bottomTooltip {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}

.bottomTooltip:after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0,0,0,.9);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.leftTooltip {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.leftTooltip:after {
  border-left: 9px solid rgba(0,0,0,.9);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rightTooltip {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.rightTooltip:after {
  border-right: 9px solid rgba(0,0,0,.9);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tooltipMessage {
  position: relative;
  background: rgba(0,0,0,.9);
  border-radius: 3px;
  color: #fff;
  line-height: 1.4;
  padding: 15px;
  text-align: center;
  font-size: 12px;
  word-break: break-word;
}
