.modalWrapper {

}

.modalWrapper input[type=number]::-webkit-inner-spin-button,
.modalWrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modalForm {
  padding: 0;
}

.modalWrapper .modalForm div.modal {
  position: relative;
  width: auto;
  pointer-events: none;
  max-width: 500px;
  margin: 1.75rem auto;
  color: white;
  cursor: default;
  transition: top 5s;
}

.modalContent {
  background-color: #10182c;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}

.modalTitle {
  font-size: 25px;
}

.errorInModal {
  width: 250px;
}

.modalCloseButton {
  border: 0;
  color: white;
  background-color: transparent;
  font-size: 25px;
  cursor: pointer;
}

.modalCloseButton:hover {
  color: #ff5722;
}


div.modalShow {
  padding-right: 17px;
  display: block;
  opacity: 1;
  overflow: auto;
  animation-name: appear;
  animation-duration: 0.5s;
}

div.modalFadeOut {
  animation-name: disappear;
  animation-duration: 0.5s;
}

.modalWrapper .modalForm .modal.bigModal {
  max-width: 900px;
}

.modalBlackScreen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
  cursor: default;
}

.modalDisabled .modal {
  pointer-events: none;
}

.modal .buttonLink:hover,
.modal .link:hover {
  color: #9A9A9A;
}

div[class="modal-backdrop fade show"] {
  display: none;
  pointer-events: none;
}

@keyframes appear {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes disappear {
  from {opacity: 1;}
  to {opacity: 0;}
}


