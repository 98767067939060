.infoButton {
  font-size: 20px;
}

.trackerWrapper {
  text-align: center;
}

.stepTracker {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 15px;
  border: 2px solid #e2e3e5;
  line-height: 18px;
  background-color: #e2e3e5;
  transition: all 0.5s;
  color: #10182c;
  pointer-events: none;
}

.current .stepTracker {
  border: 2px solid #10182c;
  background-color: #ff5722!important;
  transform: scale(1.3);
  color: white;
}

.stepLiner {
  display: inline-block;
  width: 30px;
  height: 0px;
  border-top: 2px solid #e2e3e5;
  margin-bottom: 6px;
  transition: all 0.5s;
}

.complete .stepTracker {
  background-color: #10182c;
  border: 2px solid #10182c;
  color: white;
  cursor: pointer;
  pointer-events: all;
}

.crossedStep4 .stepTracker {
  cursor: default!important;
}

.complete .stepLiner {
  border-top: 2px solid #10182c;
}

.crossedStep4.complete .stepLiner {
  border-top: 2px solid #e2e3e5;
}

.adSenseAccountDialog {
  background-image: url(../../common/images/adsense_2.png);
  width: 400px;
  height: 398px;
  background-size: auto 100%;
}

.gamAccountDialog {
  background-image: url(../../common/images/gam_account_dialog.png);
  width: 400px;
  height: 252px;
}

.adxAccountDialog {
  background-image: url(../../common/images/adx_2.png);
  width: 400px;
  height: 398px;
  background-size: auto 100%;
}

.adSenseAllocation {
  background-image: url(../../common/images/adsense_ad_unit_list.png);
  width: 500px;
  height: 270px;
  background-size: 100% auto;
}

span.syncBtnImg {
  background-image: url(../../common/images/sync_btn.png);
  width: 25px;
  height: 25px;
  margin-bottom: -7px!important;
  background-size: 100% auto;
}

span.plusBtnImg {
  background-image: url(../../common/images/plus_btn.png);
  width: 25px;
  height: 25px;
  margin-bottom: -7px!important;
  background-size: 100% auto;
}

span.gamBtnImg {
  background-image: url(../../common/images/gam_btn.png);
  width: 112px;
  height: 23px;
  background-size: 100% auto;
  margin-bottom: -7px;
}

.leafUnitImg {
  background-image: url(../../common/images/unit-onboard-leaf.png);
  width: 500px;
  height: 270px;
}

.branchUnitImg {
  background-image: url(../../common/images/unit-onboard-branch.png);
  width: 500px;
  height: 270px;
}

.permissionDenied {
  border: 1px solid #dc3545;
  padding: 10px;
  border-radius: 5px;
}

.plusBtnImg,
.syncBtnImg,
.gamBtnImg,
.adSenseAllocation,
.adSenseAccountDialog,
.adxAccountDialog,
.leafUnitImg,
.branchUnitImg,
.gamAccountDialog {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin: 0 auto;
}
