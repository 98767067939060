.table {
  border: 1px solid #9A9A9A;
  font-size: 21px;
}

div.tableHeader {
  font-size: 22px;
  margin-right: 0px;
  margin-left: 0px;
  color: #ff5722;
}

div.tableRow {
  border-top: 1px solid #9A9A9A;
  margin-right: 0px;
  margin-left: 0px;
  transition: background-color 0.5s, color 0.5s;
}

div.tableRow:hover {
  background-color: #EEEEEE;
}

.tableColumn {
  border-right: 1px solid #9A9A9A;
  padding: 10px;
  text-align: center;
}

.tableColumn:last-child {
  border-right: 0;
}
