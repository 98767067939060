.problems {
  text-align: left;
  font-weight: bold;
  background-color: transparent;
  color: #dc3545;
}

.problems:hover {
  color: #dc3545;
}

.problemsList {
  max-height: 400px;
  overflow: auto;
}

.problem {
  padding: 15px 0px;
  border-bottom: 1px solid white;
}

.problem:last-child {
  border-bottom: 0;
}

.problemTitle {
  font-weight: bold;
}

.problemSuggestion {
  font-size: 16px;
  padding-left: 15px;
}
