.statTooltipWrapper {
  padding: 4px;
}

.subHead {
  text-align: center;
}

.mainHead {
  text-align: center;
  font-weight: 600;
}

.diagramWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.stat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gptIndicator {
  width: 8px;
  height: 8px;
  background-color: skyblue;
  margin-right: 4px;
}

.ampIndicator {
  width: 8px;
  height: 8px;
  background-color: orange;
  margin-right: 4px;
}

.othersIndicator {
  width: 8px;
  height: 8px;
  background-color: lightgray;
  margin-right: 4px;
}
