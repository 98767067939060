.adManagerImage {
  background-image: url(../../common/images/ad_manager_header_raw.png);
  margin-top: 10px;
  margin-bottom: 10px;
}

.adManager360Image {
  margin-top: 10px;
  background-image: url(../../common/images/ad_manager360_header.png);
}

span.billingSection {
  background-size: auto 100%;
  background-image: url(../../common/images/gam_billing_section_focus.png);
  width: 510px;
  height: 300px;
}

div.gamLogo {
  display: inline-block;
  background-image: url(../../common/images/gam_logo.png);
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transform: scale(1.25);
  position: relative;
  top: 4px;
}

.adSenseStep1 {
  background-image: url(../../common/images/adsense_payment_setting.png);
}

.adSenseStep2 {
  background-image: url(../../common/images/adsense_payment_profile_country.png);
}

.adManagerStep1 {
  background-image: url(../../common/images/admanager_payment_setting.png);
}

.adManagerStep2 {
  background-image: url(../../common/images/admanager_payment_profile.png);
}

.gamLogo,
.billingSection,
.adManagerImage,
.adManager360Image {
  display: inline-block;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 510px;
  height: 50px;
}

.adSenseStep1,
.adSenseStep2,
.adManagerStep1,
.adManagerStep2 {
  display: inline-block;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 300px;
  height: 156px;
  transition: transform 0.5s;
  margin: 5px 2px;
  pointer-events: all;
  cursor: zoom-in;
}

div[class="modal-body"] .adSenseStep1,
div[class="modal-body"] .adSenseStep2,
div[class="modal-body"] .adManagerStep1,
div[class="modal-body"] .adManagerStep2 {
  pointer-events: none;
  width: 850px;
  height: 500px;
}

.adSenseStep1:hover,
.adSenseStep2:hover,
.adManagerStep1:hover,
.adManagerStep2:hover {
  opacity: 0.8;
}

.adManagerBtn span {
  line-height: 30px;
}

.infoTooltip {
  margin: 5px;
  font-size: 20px;
}

.infoTitle {
  font-size: 16px;
}
