.summaryWrapper > div {
  margin-left: 0px;
  margin-right: 0px;
}

.titleWrapper {
  padding: 15px;
  margin-left: 0px;
  margin-right: 0px;
}

.titleWrapper.inventoryLabel {
  position: relative;
  padding: 6px 0px 6px 5px;
  background-color: #FEFEFE;
  margin-left: 0px;
}

.statusWrapper {
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
}

.accountItem > .titleWrapper,
.network > .titleWrapper {
  padding: 15px 0px;
  margin: 0px;
}

.account > .titleWrapper {
  padding: 15px;
  margin: 0px;
}

.statusWrapper > span {
  margin-right: 5px;
}

.networkCandidate > .titleWrapper,
.network > .titleWrapper,
.yieldSet > .titleWrapper,
.account > .titleWrapper,
.inventoryLabel.titleWrapper,
.inventoryItem {
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
  border-bottom: 1px solid #9A9A9A;
}

.accountItem > .titleWrapper,
.network > .titleWrapper,
.yieldSet > .titleWrapper,
.titleWrapper.inventoryLabel,
.inventoryItem {
  border: 1px solid #9A9A9A;
  margin-top: 5px;
}

.displayOnly .network > .titleWrapper {
  margin-top: 0;
}

.displayOnly .network {
  padding-top: 5px;
}

.displayOnly .inventoryItem {
  text-align: left;
}

.inventoryItem .caret {
  display: none;
}

.inModal {
  max-height: 400px;
  overflow-y: auto;
}

.inModal .networkCandidate > .titleWrapper {
  color: white;
}

.inModal .networkCandidate:last-child > .titleWrapper {
  border: 0;
}

.networkCandidate > .titleWrapper {
  color: #666666;
}

.inventoryItem > div {
  padding-right: 0;
  padding-left: 15px;
}

.yieldSet > .titleWrapper {
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 5px;
}

.inventoryItem > .titleWrapper {
  padding-left: 5px;
}

.networkCandidate > .titleWrapper:hover .caret {
  display: inline;
}

.inventoryItem.haveProblems {
  cursor: default;
}

.inventoryItem.haveProblems .editBtn {
  display: none;
}

.inventoryItem.haveProblems:hover {
  background-color: #FFFFFF;
}

.networkCandidate > .titleWrapper:hover,
.network > .titleWrapper:hover,
.yieldSet > .titleWrapper:hover,
.account > .titleWrapper:hover,
.inventoryLabel.titleWrapper:hover,
.inventoryItem:hover {
  background-color: #EEEEEE;
  color: #10182c;
}

.displayOnly .networkCandidate > .titleWrapper:hover,
.displayOnly .network > .titleWrapper:hover,
.displayOnly .yieldSet > .titleWrapper:hover,
.displayOnly .account > .titleWrapper:hover,
.displayOnly .inventoryLabel.titleWrapper:hover,
.displayOnly .inventoryItem:hover {
  background-color: #FFFFFF;
  color: #10182c;
  cursor: default;
}

.actionsWrapper {
  text-align: right;
}

.actionsWrapper img {
  margin-right: 3px;
  margin-left: 3px;
}

.searchButtonWrapper {
  position: relative;
  padding-right: 40px;
}

.accountsHeader {
  border-left: 1px solid #9A9A9A;
  border-top: 1px solid #9A9A9A;
  border-right: 1px solid #9A9A9A;
}

.accountsWrapper {

}

.searchButtonWrapper > span {
  position: absolute;
  top: 0px;
  right: 0px;
}

.accountsHeader input {
  border-top: 0px;
  border-left: 0px;
}

.searchButtonWrapper .newAccBtn {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: center;
  padding-top: 9px;
}

.caret {
  padding-right: 7px;
  margin-right: 8px;
}

.yieldSet .stop:hover {
  background-color: #dc3545;
  color: white;
}

.yieldSet .run:hover {
  background-color: #28a745;
  color: white;
}

.inventoryItem .stop,
.inventoryItem .run {
  color: white;
}

.inventoryItem .stop:hover {
  color: #dc3545;
  background-color: transparent;
}

.inventoryItem .stop:hover {
  color: #dc3545;
  background-color: transparent;
}

.errorMsg.titleWrapper {
  pointer-events: none;
}

.networkCandidate .caret {
  display: none;
}

.accountItem > .hider,
.showContent > .hider,
.showYieldSets > .hider {
  display: inline;
  opacity: 1;
  background-color: white;
}

.hider {
  display: none;
  opacity: 0;
  background-color: transparent;
  position: absolute;
  width: 15px;
  left: 5px;
  bottom: 0;
  pointer-events: none;
  height: 0px;
  transition: all 100ms;
}

/* List wrappers */
.adUnits,
.placements,
.content,
.yieldSets {
  position: relative;
  overflow: hidden;
  max-height: 0px;
}

.networks,
.showContent .content,
.showYieldSets .yieldSets {
  border-left: 1px solid #9A9A9A;
}

.network > .connector,
.showContent .inventoryLabel > .connector,
.showYieldSets .yieldSet > .connector {
  border-top: 1px solid #9A9A9A;
}

.network > .connector {
  width: 15px;
}

.yieldSets {
  border-left: 1px solid white;
  margin-left: 15px;
  transition: border-left 350ms;
}

.content {
  padding: 0px 15px;
  margin-right: -15px;
  margin-left: 15px;
  border-left: 1px solid white;
  transition: border-left 350ms;
}

.yieldSet {
  position: relative;
}

.connector {
  position: absolute;
  left: -15px;
  top: 30px;
  width: 20px;
  border-top: 1px solid white;
  pointer-events: none;
  transition: border-top 350ms;
}

.inventoryLabel > .connector {
  top: 15px;
  left: -16px;
  width: 15px;
}

.placements > .connector,
.adUnits > .connector {
  width: 35px;
  display: none;
}


.placements,
.adUnits {
  position: relative;
  margin-right: -15px;
  margin-left: 0px;
  padding-left: 20px;
}

.yieldSet.hideAdUnits .adUnits,
.yieldSet.hidePlacements .placements,
.yieldSet.hideContent .content,
.network.hideYieldSets .yieldSets {
  max-height: 0px;
  animation-name: hideAnimation;
  animation-delay: 0ms;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.54, 1, .47, 1);
}

@keyframes hideAnimation {
  from {max-height: 500px;}
  to {max-height: 0px}
}

.yieldSet.showAdUnits .adUnits,
.yieldSet.showPlacements .placements,
.yieldSet.showContent .content,
.network.showYieldSets .yieldSets {
  max-height: 500px;
  animation-name: showAnimation;
  animation-delay: 0ms;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.71, 0, .46, 0);
}

@keyframes showAnimation {
  from {max-height: 0px;}
  99.9% {max-height: 500px;}
  to {max-height: none;}
}

.yieldSet {
  margin: 0px 15px;
}

input[type="text"].smallInput {
  font-size: 18px;
}

/*Single Object*/
.networkCandidate,
.account,
.network {

}

.account,
.network {
  position: relative;
  background-color: #ffffff;
  color: black;
}

.inventoryItem {
  padding: 0px 15px;
  border-bottom: 1px solid #9A9A9A;
}

.account.selected,
.inventoryItem.selected {
  background-color: #ff5722;
  color: #ffffff;
}

.inventoryItem.selected .caret {
  display: inline;
}

.inventoryItem.selected .run,
.inventoryItem.selected .stop {
  display: none;
}

.networkCandidate {
  color: #10182c;
}

.networkCandidate.selected > .titleWrapper {
  color: black;
  background-color: white
}

.propertiesWrapper {
  padding: 15px;
  font-size: 0.8em;
}

.property {
  padding: 0px 15px;
}

.property:last-child {
  padding-bottom: 10px;
}

.propertyName {
  display: inline-block;
  width: 200px;
}

.propertyValue {
  display: inline-block;
}

.performance {
  float: right;
}

.accountItem > div {
  padding-left: 15px;
  margin-left: 15px;
}

div.accountsWrapper {
  padding-left: 0px;
  padding-right: 0px;
}

div.accountsWrapper input {
  border-bottom: 0;
}

.accountsList {
  display: none;
  max-height: 450px;
  overflow: auto;
  border: 1px solid #9A9A9A;
}

.workingArea {
  max-height: 650px;
  overflow: auto;
}

.overviewHeader {
  background-color: white;
  border-bottom: 1px solid #9A9A9A;
}

.headerActions .inputWrapper {
  display: inline-block;
  width: 200px;
  padding: 0px 5px;
}

.keyValues1,
.keyValues2,
.apiAccess1,
.apiAccess2,
.apiAccess3 {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 800px;
  height: 380px;
  margin-left: 15px;
}

.keyValues1 {
  background-image: url(../../common/images/key_values_1.png);
}

.keyValues2 {
  background-image: url(../../common/images/key_values_2.png);
}

.apiAccess1 {
  background-image: url(../../common/images/api_access_1.png);
}

.apiAccess2 {
  background-image: url(../../common/images/api_access_2.png);
}

.apiAccess3 {
  background-image: url(../../common/images/api_access_3.png);
}


@media (min-width: 768px) {
  .accountsSelect {
    display: none;
  }

  .accountsList {
    display: block;
  }
}
